












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class RNPIndex extends Vue {
}
